import { default as _91slug_93wbjkj0xi4EMeta } from "/usr/src/nuxt-app/pages/blog/[slug].vue?macro=true";
import { default as indexJBbsGA1qIvMeta } from "/usr/src/nuxt-app/pages/blog/index.vue?macro=true";
import { default as _91id_93Dbf7gdH6cZMeta } from "/usr/src/nuxt-app/pages/c/[id].vue?macro=true";
import { default as collaborateXGWAcEe3zFMeta } from "/usr/src/nuxt-app/pages/collaborate.vue?macro=true";
import { default as indexPUFo0W6wwCMeta } from "/usr/src/nuxt-app/pages/color-tracking-form/index.vue?macro=true";
import { default as indexNWHekY27khMeta } from "/usr/src/nuxt-app/pages/company/index.vue?macro=true";
import { default as guideV4q2TCcNAaMeta } from "/usr/src/nuxt-app/pages/download/guide.vue?macro=true";
import { default as helpwF5N5w1XRXMeta } from "/usr/src/nuxt-app/pages/download/help.vue?macro=true";
import { default as index0a58YKWW2WMeta } from "/usr/src/nuxt-app/pages/download/index.vue?macro=true";
import { default as ai_45color_45match9GTzDbP5S8Meta } from "/usr/src/nuxt-app/pages/features/ai-color-match.vue?macro=true";
import { default as ai_45crop_45imageNqHQG6TqpRMeta } from "/usr/src/nuxt-app/pages/features/ai-crop-image.vue?macro=true";
import { default as background_45adjustmentsF2YzgsnNi5Meta } from "/usr/src/nuxt-app/pages/features/background-adjustments.vue?macro=true";
import { default as background_45removal_45servicehRFhwfPR0pMeta } from "/usr/src/nuxt-app/pages/features/background-removal-service.vue?macro=true";
import { default as background_45replacerk8TRwSPoSVMeta } from "/usr/src/nuxt-app/pages/features/background-replacer.vue?macro=true";
import { default as batch_45edits_45old65G7oBlIJbMeta } from "/usr/src/nuxt-app/pages/features/batch-edits-old.vue?macro=true";
import { default as batch_45editsbHhdPZRDPSMeta } from "/usr/src/nuxt-app/pages/features/batch-edits.vue?macro=true";
import { default as blemish_45removalIorEq0OJXMMeta } from "/usr/src/nuxt-app/pages/features/blemish-removal.vue?macro=true";
import { default as body_45editoraH31Rk5lVYMeta } from "/usr/src/nuxt-app/pages/features/body-editor.vue?macro=true";
import { default as change_45skin_45colorOjmmPksQK9Meta } from "/usr/src/nuxt-app/pages/features/change-skin-color.vue?macro=true";
import { default as change_45smile_45filterx3utHApRkmMeta } from "/usr/src/nuxt-app/pages/features/change-smile-filter.vue?macro=true";
import { default as color_45and_45toneOER87s82QPMeta } from "/usr/src/nuxt-app/pages/features/color-and-tone.vue?macro=true";
import { default as double_45chin_45removerYmkeSgntleMeta } from "/usr/src/nuxt-app/pages/features/double-chin-remover.vue?macro=true";
import { default as exclusive_45presetsMy3e00KqwzMeta } from "/usr/src/nuxt-app/pages/features/exclusive-presets.vue?macro=true";
import { default as hair_45color_45changer3QwONoy2kKMeta } from "/usr/src/nuxt-app/pages/features/hair-color-changer.vue?macro=true";
import { default as headshot_45photographyECJJ1WIlQZMeta } from "/usr/src/nuxt-app/pages/features/headshot-photography.vue?macro=true";
import { default as indexFnt9C7QJk0Meta } from "/usr/src/nuxt-app/pages/features/index.vue?macro=true";
import { default as photo_45glare_45removerwJBGVOATZnMeta } from "/usr/src/nuxt-app/pages/features/photo-glare-remover.vue?macro=true";
import { default as portrait_45retouching_45oldFATZ30Ya3MMeta } from "/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue?macro=true";
import { default as portrait_45retouchingAksoD8yz8IMeta } from "/usr/src/nuxt-app/pages/features/portrait-retouching.vue?macro=true";
import { default as preset_45photo_45editorymy7aazJbjMeta } from "/usr/src/nuxt-app/pages/features/preset-photo-editor.vue?macro=true";
import { default as remove_45wrinkles_45from_45clothesBl2FOlMC6SMeta } from "/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue?macro=true";
import { default as sky_45replacementdP5cHpXJktMeta } from "/usr/src/nuxt-app/pages/features/sky-replacement.vue?macro=true";
import { default as tethering_45shooting_45software7O2Nz06HE2Meta } from "/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue?macro=true";
import { default as v4_450XsforcqoMsMeta } from "/usr/src/nuxt-app/pages/features/updates/v4-0.vue?macro=true";
import { default as wedding_45photographyVy7IXW2MNzMeta } from "/usr/src/nuxt-app/pages/features/wedding-photography.vue?macro=true";
import { default as indexev3TJQucaPMeta } from "/usr/src/nuxt-app/pages/help/index.vue?macro=true";
import { default as moreOVluC3nZJEMeta } from "/usr/src/nuxt-app/pages/help/more.vue?macro=true";
import { default as indexkDuSkIl3GDMeta } from "/usr/src/nuxt-app/pages/home/index.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as invitationrBaNZ2DkmLMeta } from "/usr/src/nuxt-app/pages/invitation.vue?macro=true";
import { default as indexF2df7XqkkyMeta } from "/usr/src/nuxt-app/pages/ipad/index.vue?macro=true";
import { default as landing_combo_bf_giftEPvKYHUG5lMeta } from "/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue?macro=true";
import { default as landing_combo_bfXXKc8ZlKfaMeta } from "/usr/src/nuxt-app/pages/landing_combo_bf.vue?macro=true";
import { default as landing_combo_mul_lnBUqMEgm5iuMeta } from "/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue?macro=true";
import { default as landing_combo_mul_specD1pPPQiOtcMeta } from "/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue?macro=true";
import { default as landing_combo_mul_t_xsROgelS5dGQMeta } from "/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue?macro=true";
import { default as landing_combo_mul_xsVlskHzz7EGMeta } from "/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue?macro=true";
import { default as landing_combo_mulgwZzxFhn4mMeta } from "/usr/src/nuxt-app/pages/landing_combo_mul.vue?macro=true";
import { default as landing_combo_trialgqrpO5OaecMeta } from "/usr/src/nuxt-app/pages/landing_combo_trial.vue?macro=true";
import { default as landing_comboEqNna079RCMeta } from "/usr/src/nuxt-app/pages/landing_combo.vue?macro=true";
import { default as landing_cus_combo9rAaBU03XpMeta } from "/usr/src/nuxt-app/pages/landing_cus_combo.vue?macro=true";
import { default as landing_dSBl7OkwOHcMeta } from "/usr/src/nuxt-app/pages/landing_d.vue?macro=true";
import { default as landing_generic9iPPHkb3nNMeta } from "/usr/src/nuxt-app/pages/landing_generic.vue?macro=true";
import { default as landing_jpLD9c09rKL1Meta } from "/usr/src/nuxt-app/pages/landing_jp.vue?macro=true";
import { default as landing_k_s_lnqpB5UsuSudMeta } from "/usr/src/nuxt-app/pages/landing_k_s_ln.vue?macro=true";
import { default as landing_k_s1FLCG6i0AeMeta } from "/usr/src/nuxt-app/pages/landing_k_s.vue?macro=true";
import { default as landing_kedGsVK9vKtMeta } from "/usr/src/nuxt-app/pages/landing_k.vue?macro=true";
import { default as landing_msOAeoZMK2HUMeta } from "/usr/src/nuxt-app/pages/landing_ms.vue?macro=true";
import { default as landing_promotionQyeGm5EpWVMeta } from "/usr/src/nuxt-app/pages/landing_promotion.vue?macro=true";
import { default as landing_s_uskLroAx9CWSMeta } from "/usr/src/nuxt-app/pages/landing_s_us.vue?macro=true";
import { default as landing_sg6RaPSoiCOMeta } from "/usr/src/nuxt-app/pages/landing_s.vue?macro=true";
import { default as landing_usepqMEk2rKhMeta } from "/usr/src/nuxt-app/pages/landing_us.vue?macro=true";
import { default as landingtYvM9RYcEsMeta } from "/usr/src/nuxt-app/pages/landing.vue?macro=true";
import { default as combo_proceedcHvE4mzLgfMeta } from "/usr/src/nuxt-app/pages/payment/combo_proceed.vue?macro=true";
import { default as combo_45subctpuBucKX3Meta } from "/usr/src/nuxt-app/pages/payment/combo-sub.vue?macro=true";
import { default as comboytBzG1jcWhMeta } from "/usr/src/nuxt-app/pages/payment/combo.vue?macro=true";
import { default as indexahiMYhfP5QMeta } from "/usr/src/nuxt-app/pages/payment/index.vue?macro=true";
import { default as resultHW2I97Yj3mMeta } from "/usr/src/nuxt-app/pages/payment/result.vue?macro=true";
import { default as licenseaM2iQ7Nv9LMeta } from "/usr/src/nuxt-app/pages/policy/license.vue?macro=true";
import { default as privacyViD61SoAR1Meta } from "/usr/src/nuxt-app/pages/policy/privacy.vue?macro=true";
import { default as termsPtyRC7fsTmMeta } from "/usr/src/nuxt-app/pages/policy/terms.vue?macro=true";
import { default as profile9minSuqEAQMeta } from "/usr/src/nuxt-app/pages/profile.vue?macro=true";
import { default as index2MwOG14fu8Meta } from "/usr/src/nuxt-app/pages/rdm/index.vue?macro=true";
import { default as indexA4hD9UO88wMeta } from "/usr/src/nuxt-app/pages/rdm/result/index.vue?macro=true";
import { default as indexM2Ny0tFVqQMeta } from "/usr/src/nuxt-app/pages/redirect/index.vue?macro=true";
import { default as sensitiveLoginZXXKQEQf1tMeta } from "/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue?macro=true";
import { default as indexhQJgsRAftHMeta } from "/usr/src/nuxt-app/pages/referral/index.vue?macro=true";
import { default as indexRXP4sNqaBDMeta } from "/usr/src/nuxt-app/pages/reset/index.vue?macro=true";
import { default as _91id_93NVf0A3DMvLMeta } from "/usr/src/nuxt-app/pages/s/[id].vue?macro=true";
import { default as indext6cu0RBCUJMeta } from "/usr/src/nuxt-app/pages/search/index.vue?macro=true";
import { default as startbvdlWNBw3MMeta } from "/usr/src/nuxt-app/pages/start.vue?macro=true";
import { default as invitationWJ4MZy7CAUMeta } from "/usr/src/nuxt-app/pages/welfare/invitation.vue?macro=true";
import { default as index649PJ6jxVRMeta } from "/usr/src/nuxt-app/pages/welfare/invitee/index.vue?macro=true";
import { default as component_45stub080fa1cgF9Meta } from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub080fa1cgF9 } from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___vi",
    path: "/vi/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___ko",
    path: "/ko/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___ja",
    path: "/ja/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___it",
    path: "/it/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___pt",
    path: "/pt/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___es_Es",
    path: "/es_Es/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___zh-Hant",
    path: "/zh-Hant/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___pl",
    path: "/pl/blog/:slug()",
    meta: _91slug_93wbjkj0xi4EMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___vi",
    path: "/vi/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ko",
    path: "/ko/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ja",
    path: "/ja/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___de",
    path: "/de/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___es",
    path: "/es/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___it",
    path: "/it/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___pt",
    path: "/pt/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___es_Es",
    path: "/es_Es/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___zh-Hant",
    path: "/zh-Hant/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___pl",
    path: "/pl/blog",
    meta: indexJBbsGA1qIvMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "c-id___en",
    path: "/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___vi",
    path: "/vi/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___ko",
    path: "/ko/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___ja",
    path: "/ja/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___de",
    path: "/de/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___fr",
    path: "/fr/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___es",
    path: "/es/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___it",
    path: "/it/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___pt",
    path: "/pt/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___es_Es",
    path: "/es_Es/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___zh-Hant",
    path: "/zh-Hant/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "c-id___pl",
    path: "/pl/c/:id()",
    meta: _91id_93Dbf7gdH6cZMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/c/[id].vue").then(m => m.default || m)
  },
  {
    name: "collaborate___en",
    path: "/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___vi",
    path: "/vi/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___ko",
    path: "/ko/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___ja",
    path: "/ja/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___de",
    path: "/de/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___fr",
    path: "/fr/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___es",
    path: "/es/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___it",
    path: "/it/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___pt",
    path: "/pt/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___es_Es",
    path: "/es_Es/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___zh-Hant",
    path: "/zh-Hant/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "collaborate___pl",
    path: "/pl/collaborate",
    component: () => import("/usr/src/nuxt-app/pages/collaborate.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___en",
    path: "/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___vi",
    path: "/vi/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___ko",
    path: "/ko/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___ja",
    path: "/ja/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___de",
    path: "/de/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___fr",
    path: "/fr/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___es",
    path: "/es/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___it",
    path: "/it/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___pt",
    path: "/pt/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___es_Es",
    path: "/es_Es/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___zh-Hant",
    path: "/zh-Hant/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "color-tracking-form___pl",
    path: "/pl/color-tracking-form",
    meta: indexPUFo0W6wwCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/color-tracking-form/index.vue").then(m => m.default || m)
  },
  {
    name: "company___en",
    path: "/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___vi",
    path: "/vi/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___ko",
    path: "/ko/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___ja",
    path: "/ja/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___de",
    path: "/de/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___fr",
    path: "/fr/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___es",
    path: "/es/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___it",
    path: "/it/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___pt",
    path: "/pt/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___es_Es",
    path: "/es_Es/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___zh-Hant",
    path: "/zh-Hant/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___pl",
    path: "/pl/company",
    component: () => import("/usr/src/nuxt-app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___en",
    path: "/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___vi",
    path: "/vi/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___ko",
    path: "/ko/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___ja",
    path: "/ja/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___de",
    path: "/de/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___fr",
    path: "/fr/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___es",
    path: "/es/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___it",
    path: "/it/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___pt",
    path: "/pt/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___es_Es",
    path: "/es_Es/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___zh-Hant",
    path: "/zh-Hant/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-guide___pl",
    path: "/pl/download/guide",
    meta: guideV4q2TCcNAaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/guide.vue").then(m => m.default || m)
  },
  {
    name: "download-help___en",
    path: "/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___vi",
    path: "/vi/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___ko",
    path: "/ko/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___ja",
    path: "/ja/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___de",
    path: "/de/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___fr",
    path: "/fr/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___es",
    path: "/es/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___it",
    path: "/it/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___pt",
    path: "/pt/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___es_Es",
    path: "/es_Es/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___zh-Hant",
    path: "/zh-Hant/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download-help___pl",
    path: "/pl/download/help",
    meta: helpwF5N5w1XRXMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/download/help.vue").then(m => m.default || m)
  },
  {
    name: "download___en",
    path: "/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___vi",
    path: "/vi/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___ko",
    path: "/ko/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___ja",
    path: "/ja/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___de",
    path: "/de/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___fr",
    path: "/fr/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___es",
    path: "/es/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___it",
    path: "/it/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___pt",
    path: "/pt/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___es_Es",
    path: "/es_Es/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___zh-Hant",
    path: "/zh-Hant/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download___pl",
    path: "/pl/download",
    component: () => import("/usr/src/nuxt-app/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___en",
    path: "/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___vi",
    path: "/vi/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___ko",
    path: "/ko/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___ja",
    path: "/ja/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___de",
    path: "/de/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___fr",
    path: "/fr/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___es",
    path: "/es/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___it",
    path: "/it/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___pt",
    path: "/pt/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___es_Es",
    path: "/es_Es/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___zh-Hant",
    path: "/zh-Hant/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-color-match___pl",
    path: "/pl/features/ai-color-match",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-color-match.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___en",
    path: "/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___vi",
    path: "/vi/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___ko",
    path: "/ko/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___ja",
    path: "/ja/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___de",
    path: "/de/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___fr",
    path: "/fr/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___es",
    path: "/es/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___it",
    path: "/it/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___pt",
    path: "/pt/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___es_Es",
    path: "/es_Es/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___zh-Hant",
    path: "/zh-Hant/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-crop-image___pl",
    path: "/pl/features/ai-crop-image",
    component: () => import("/usr/src/nuxt-app/pages/features/ai-crop-image.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___en",
    path: "/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___vi",
    path: "/vi/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___ko",
    path: "/ko/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___ja",
    path: "/ja/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___de",
    path: "/de/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___fr",
    path: "/fr/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___es",
    path: "/es/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___it",
    path: "/it/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___pt",
    path: "/pt/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___es_Es",
    path: "/es_Es/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___zh-Hant",
    path: "/zh-Hant/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-adjustments___pl",
    path: "/pl/features/background-adjustments",
    component: () => import("/usr/src/nuxt-app/pages/features/background-adjustments.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___en",
    path: "/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___vi",
    path: "/vi/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___ko",
    path: "/ko/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___ja",
    path: "/ja/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___de",
    path: "/de/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___fr",
    path: "/fr/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___es",
    path: "/es/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___it",
    path: "/it/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___pt",
    path: "/pt/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___es_Es",
    path: "/es_Es/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___zh-Hant",
    path: "/zh-Hant/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-removal-service___pl",
    path: "/pl/features/background-removal-service",
    component: () => import("/usr/src/nuxt-app/pages/features/background-removal-service.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___en",
    path: "/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___vi",
    path: "/vi/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___ko",
    path: "/ko/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___ja",
    path: "/ja/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___de",
    path: "/de/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___fr",
    path: "/fr/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___es",
    path: "/es/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___it",
    path: "/it/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___pt",
    path: "/pt/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___es_Es",
    path: "/es_Es/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___zh-Hant",
    path: "/zh-Hant/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-background-replacer___pl",
    path: "/pl/features/background-replacer",
    component: () => import("/usr/src/nuxt-app/pages/features/background-replacer.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___en",
    path: "/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___vi",
    path: "/vi/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___ko",
    path: "/ko/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___ja",
    path: "/ja/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___de",
    path: "/de/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___fr",
    path: "/fr/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___es",
    path: "/es/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___it",
    path: "/it/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___pt",
    path: "/pt/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___es_Es",
    path: "/es_Es/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___zh-Hant",
    path: "/zh-Hant/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits-old___pl",
    path: "/pl/features/batch-edits-old",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits-old.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___en",
    path: "/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___vi",
    path: "/vi/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___ko",
    path: "/ko/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___ja",
    path: "/ja/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___de",
    path: "/de/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___fr",
    path: "/fr/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___es",
    path: "/es/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___it",
    path: "/it/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___pt",
    path: "/pt/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___es_Es",
    path: "/es_Es/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___zh-Hant",
    path: "/zh-Hant/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-batch-edits___pl",
    path: "/pl/features/batch-edits",
    component: () => import("/usr/src/nuxt-app/pages/features/batch-edits.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___en",
    path: "/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___vi",
    path: "/vi/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___ko",
    path: "/ko/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___ja",
    path: "/ja/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___de",
    path: "/de/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___fr",
    path: "/fr/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___es",
    path: "/es/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___it",
    path: "/it/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___pt",
    path: "/pt/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___es_Es",
    path: "/es_Es/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___zh-Hant",
    path: "/zh-Hant/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-blemish-removal___pl",
    path: "/pl/features/blemish-removal",
    component: () => import("/usr/src/nuxt-app/pages/features/blemish-removal.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___en",
    path: "/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___vi",
    path: "/vi/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___ko",
    path: "/ko/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___ja",
    path: "/ja/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___de",
    path: "/de/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___fr",
    path: "/fr/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___es",
    path: "/es/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___it",
    path: "/it/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___pt",
    path: "/pt/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___es_Es",
    path: "/es_Es/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___zh-Hant",
    path: "/zh-Hant/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-body-editor___pl",
    path: "/pl/features/body-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/body-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___en",
    path: "/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___vi",
    path: "/vi/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___ko",
    path: "/ko/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___ja",
    path: "/ja/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___de",
    path: "/de/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___fr",
    path: "/fr/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___es",
    path: "/es/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___it",
    path: "/it/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___pt",
    path: "/pt/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___es_Es",
    path: "/es_Es/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___zh-Hant",
    path: "/zh-Hant/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-skin-color___pl",
    path: "/pl/features/change-skin-color",
    component: () => import("/usr/src/nuxt-app/pages/features/change-skin-color.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___en",
    path: "/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___vi",
    path: "/vi/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___ko",
    path: "/ko/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___ja",
    path: "/ja/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___de",
    path: "/de/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___fr",
    path: "/fr/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___es",
    path: "/es/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___it",
    path: "/it/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___pt",
    path: "/pt/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___es_Es",
    path: "/es_Es/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___zh-Hant",
    path: "/zh-Hant/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-change-smile-filter___pl",
    path: "/pl/features/change-smile-filter",
    component: () => import("/usr/src/nuxt-app/pages/features/change-smile-filter.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___en",
    path: "/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___vi",
    path: "/vi/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___ko",
    path: "/ko/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___ja",
    path: "/ja/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___de",
    path: "/de/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___fr",
    path: "/fr/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___es",
    path: "/es/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___it",
    path: "/it/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___pt",
    path: "/pt/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___es_Es",
    path: "/es_Es/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___zh-Hant",
    path: "/zh-Hant/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-color-and-tone___pl",
    path: "/pl/features/color-and-tone",
    component: () => import("/usr/src/nuxt-app/pages/features/color-and-tone.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___en",
    path: "/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___vi",
    path: "/vi/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___ko",
    path: "/ko/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___ja",
    path: "/ja/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___de",
    path: "/de/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___fr",
    path: "/fr/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___es",
    path: "/es/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___it",
    path: "/it/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___pt",
    path: "/pt/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___es_Es",
    path: "/es_Es/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___zh-Hant",
    path: "/zh-Hant/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-double-chin-remover___pl",
    path: "/pl/features/double-chin-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/double-chin-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___en",
    path: "/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___vi",
    path: "/vi/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___ko",
    path: "/ko/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___ja",
    path: "/ja/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___de",
    path: "/de/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___fr",
    path: "/fr/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___es",
    path: "/es/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___it",
    path: "/it/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___pt",
    path: "/pt/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___es_Es",
    path: "/es_Es/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___zh-Hant",
    path: "/zh-Hant/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-exclusive-presets___pl",
    path: "/pl/features/exclusive-presets",
    component: () => import("/usr/src/nuxt-app/pages/features/exclusive-presets.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___en",
    path: "/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___vi",
    path: "/vi/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___ko",
    path: "/ko/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___ja",
    path: "/ja/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___de",
    path: "/de/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___fr",
    path: "/fr/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___es",
    path: "/es/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___it",
    path: "/it/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___pt",
    path: "/pt/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___es_Es",
    path: "/es_Es/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___zh-Hant",
    path: "/zh-Hant/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-hair-color-changer___pl",
    path: "/pl/features/hair-color-changer",
    component: () => import("/usr/src/nuxt-app/pages/features/hair-color-changer.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___en",
    path: "/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___vi",
    path: "/vi/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___ko",
    path: "/ko/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___ja",
    path: "/ja/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___de",
    path: "/de/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___fr",
    path: "/fr/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___es",
    path: "/es/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___it",
    path: "/it/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___pt",
    path: "/pt/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___es_Es",
    path: "/es_Es/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___zh-Hant",
    path: "/zh-Hant/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-headshot-photography___pl",
    path: "/pl/features/headshot-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/headshot-photography.vue").then(m => m.default || m)
  },
  {
    name: "features___en",
    path: "/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___vi",
    path: "/vi/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___ko",
    path: "/ko/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___ja",
    path: "/ja/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___de",
    path: "/de/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___fr",
    path: "/fr/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___es",
    path: "/es/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___it",
    path: "/it/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___pt",
    path: "/pt/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___es_Es",
    path: "/es_Es/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___zh-Hant",
    path: "/zh-Hant/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features___pl",
    path: "/pl/features",
    component: () => import("/usr/src/nuxt-app/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___en",
    path: "/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___vi",
    path: "/vi/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___ko",
    path: "/ko/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___ja",
    path: "/ja/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___de",
    path: "/de/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___fr",
    path: "/fr/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___es",
    path: "/es/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___it",
    path: "/it/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___pt",
    path: "/pt/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___es_Es",
    path: "/es_Es/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___zh-Hant",
    path: "/zh-Hant/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-photo-glare-remover___pl",
    path: "/pl/features/photo-glare-remover",
    component: () => import("/usr/src/nuxt-app/pages/features/photo-glare-remover.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___en",
    path: "/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___vi",
    path: "/vi/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___ko",
    path: "/ko/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___ja",
    path: "/ja/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___de",
    path: "/de/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___fr",
    path: "/fr/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___es",
    path: "/es/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___it",
    path: "/it/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___pt",
    path: "/pt/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___es_Es",
    path: "/es_Es/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___zh-Hant",
    path: "/zh-Hant/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching-old___pl",
    path: "/pl/features/portrait-retouching-old",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching-old.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___en",
    path: "/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___vi",
    path: "/vi/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___ko",
    path: "/ko/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___ja",
    path: "/ja/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___de",
    path: "/de/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___fr",
    path: "/fr/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___es",
    path: "/es/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___it",
    path: "/it/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___pt",
    path: "/pt/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___es_Es",
    path: "/es_Es/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___zh-Hant",
    path: "/zh-Hant/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-portrait-retouching___pl",
    path: "/pl/features/portrait-retouching",
    component: () => import("/usr/src/nuxt-app/pages/features/portrait-retouching.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___en",
    path: "/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___vi",
    path: "/vi/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___ko",
    path: "/ko/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___ja",
    path: "/ja/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___de",
    path: "/de/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___fr",
    path: "/fr/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___es",
    path: "/es/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___it",
    path: "/it/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___pt",
    path: "/pt/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___es_Es",
    path: "/es_Es/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___zh-Hant",
    path: "/zh-Hant/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-preset-photo-editor___pl",
    path: "/pl/features/preset-photo-editor",
    component: () => import("/usr/src/nuxt-app/pages/features/preset-photo-editor.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___en",
    path: "/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___vi",
    path: "/vi/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___ko",
    path: "/ko/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___ja",
    path: "/ja/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___de",
    path: "/de/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___fr",
    path: "/fr/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___es",
    path: "/es/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___it",
    path: "/it/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___pt",
    path: "/pt/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___es_Es",
    path: "/es_Es/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___zh-Hant",
    path: "/zh-Hant/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-remove-wrinkles-from-clothes___pl",
    path: "/pl/features/remove-wrinkles-from-clothes",
    component: () => import("/usr/src/nuxt-app/pages/features/remove-wrinkles-from-clothes.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___en",
    path: "/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___vi",
    path: "/vi/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___ko",
    path: "/ko/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___ja",
    path: "/ja/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___de",
    path: "/de/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___fr",
    path: "/fr/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___es",
    path: "/es/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___it",
    path: "/it/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___pt",
    path: "/pt/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___es_Es",
    path: "/es_Es/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___zh-Hant",
    path: "/zh-Hant/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-sky-replacement___pl",
    path: "/pl/features/sky-replacement",
    component: () => import("/usr/src/nuxt-app/pages/features/sky-replacement.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___en",
    path: "/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___vi",
    path: "/vi/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___ko",
    path: "/ko/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___ja",
    path: "/ja/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___de",
    path: "/de/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___fr",
    path: "/fr/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___es",
    path: "/es/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___it",
    path: "/it/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___pt",
    path: "/pt/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___es_Es",
    path: "/es_Es/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___zh-Hant",
    path: "/zh-Hant/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-tethering-shooting-software___pl",
    path: "/pl/features/tethering-shooting-software",
    component: () => import("/usr/src/nuxt-app/pages/features/tethering-shooting-software.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___en",
    path: "/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___vi",
    path: "/vi/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___ko",
    path: "/ko/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___ja",
    path: "/ja/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___de",
    path: "/de/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___fr",
    path: "/fr/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___es",
    path: "/es/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___it",
    path: "/it/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___pt",
    path: "/pt/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___es_Es",
    path: "/es_Es/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___zh-Hant",
    path: "/zh-Hant/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-updates-v4-0___pl",
    path: "/pl/features/updates/v4-0",
    meta: v4_450XsforcqoMsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/features/updates/v4-0.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___en",
    path: "/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___vi",
    path: "/vi/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___ko",
    path: "/ko/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___ja",
    path: "/ja/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___de",
    path: "/de/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___fr",
    path: "/fr/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___es",
    path: "/es/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___it",
    path: "/it/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___pt",
    path: "/pt/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___es_Es",
    path: "/es_Es/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___zh-Hant",
    path: "/zh-Hant/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "features-wedding-photography___pl",
    path: "/pl/features/wedding-photography",
    component: () => import("/usr/src/nuxt-app/pages/features/wedding-photography.vue").then(m => m.default || m)
  },
  {
    name: "help___en",
    path: "/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___vi",
    path: "/vi/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___ko",
    path: "/ko/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___ja",
    path: "/ja/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___de",
    path: "/de/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___fr",
    path: "/fr/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___es",
    path: "/es/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___it",
    path: "/it/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___pt",
    path: "/pt/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___es_Es",
    path: "/es_Es/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___zh-Hant",
    path: "/zh-Hant/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help___pl",
    path: "/pl/help",
    component: () => import("/usr/src/nuxt-app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "help-more___en",
    path: "/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___vi",
    path: "/vi/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___ko",
    path: "/ko/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___ja",
    path: "/ja/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___de",
    path: "/de/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___fr",
    path: "/fr/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___es",
    path: "/es/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___it",
    path: "/it/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___pt",
    path: "/pt/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___es_Es",
    path: "/es_Es/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___zh-Hant",
    path: "/zh-Hant/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "help-more___pl",
    path: "/pl/help/more",
    component: () => import("/usr/src/nuxt-app/pages/help/more.vue").then(m => m.default || m)
  },
  {
    name: "home___en",
    path: "/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___vi",
    path: "/vi/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___ko",
    path: "/ko/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___ja",
    path: "/ja/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___de",
    path: "/de/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___fr",
    path: "/fr/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___es",
    path: "/es/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___it",
    path: "/it/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___pt",
    path: "/pt/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___es_Es",
    path: "/es_Es/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___zh-Hant",
    path: "/zh-Hant/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___pl",
    path: "/pl/home",
    meta: indexkDuSkIl3GDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___vi",
    path: "/vi",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ko",
    path: "/ko",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ja",
    path: "/ja",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es_Es",
    path: "/es_Es",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh-Hant",
    path: "/zh-Hant",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/pl",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation___en",
    path: "/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___vi",
    path: "/vi/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___ko",
    path: "/ko/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___ja",
    path: "/ja/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___de",
    path: "/de/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___fr",
    path: "/fr/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___es",
    path: "/es/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___it",
    path: "/it/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___pt",
    path: "/pt/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___es_Es",
    path: "/es_Es/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___zh-Hant",
    path: "/zh-Hant/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "invitation___pl",
    path: "/pl/invitation",
    meta: invitationrBaNZ2DkmLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/invitation.vue").then(m => m.default || m)
  },
  {
    name: "ipad___en",
    path: "/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___vi",
    path: "/vi/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___ko",
    path: "/ko/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___ja",
    path: "/ja/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___de",
    path: "/de/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___fr",
    path: "/fr/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___es",
    path: "/es/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___it",
    path: "/it/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___pt",
    path: "/pt/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___es_Es",
    path: "/es_Es/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___zh-Hant",
    path: "/zh-Hant/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "ipad___pl",
    path: "/pl/ipad",
    meta: indexF2df7XqkkyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/ipad/index.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___en",
    path: "/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___vi",
    path: "/vi/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___ko",
    path: "/ko/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___ja",
    path: "/ja/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___de",
    path: "/de/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___fr",
    path: "/fr/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___es",
    path: "/es/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___it",
    path: "/it/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___pt",
    path: "/pt/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___es_Es",
    path: "/es_Es/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___zh-Hant",
    path: "/zh-Hant/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf_gift___pl",
    path: "/pl/landing_combo_bf_gift",
    meta: landing_combo_bf_giftEPvKYHUG5lMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf_gift.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___en",
    path: "/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___vi",
    path: "/vi/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___ko",
    path: "/ko/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___ja",
    path: "/ja/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___de",
    path: "/de/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___fr",
    path: "/fr/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___es",
    path: "/es/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___it",
    path: "/it/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___pt",
    path: "/pt/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___es_Es",
    path: "/es_Es/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___zh-Hant",
    path: "/zh-Hant/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_bf___pl",
    path: "/pl/landing_combo_bf",
    meta: landing_combo_bfXXKc8ZlKfaMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_bf.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___en",
    path: "/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___vi",
    path: "/vi/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___ko",
    path: "/ko/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___ja",
    path: "/ja/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___de",
    path: "/de/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___fr",
    path: "/fr/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___es",
    path: "/es/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___it",
    path: "/it/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___pt",
    path: "/pt/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___es_Es",
    path: "/es_Es/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___zh-Hant",
    path: "/zh-Hant/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_ln___pl",
    path: "/pl/landing_combo_mul_ln",
    meta: landing_combo_mul_lnBUqMEgm5iuMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___en",
    path: "/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___vi",
    path: "/vi/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___ko",
    path: "/ko/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___ja",
    path: "/ja/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___de",
    path: "/de/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___fr",
    path: "/fr/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___es",
    path: "/es/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___it",
    path: "/it/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___pt",
    path: "/pt/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___es_Es",
    path: "/es_Es/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___zh-Hant",
    path: "/zh-Hant/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_spec___pl",
    path: "/pl/landing_combo_mul_spec",
    meta: landing_combo_mul_specD1pPPQiOtcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_spec.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___en",
    path: "/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___vi",
    path: "/vi/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___ko",
    path: "/ko/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___ja",
    path: "/ja/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___de",
    path: "/de/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___fr",
    path: "/fr/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___es",
    path: "/es/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___it",
    path: "/it/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___pt",
    path: "/pt/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___es_Es",
    path: "/es_Es/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___zh-Hant",
    path: "/zh-Hant/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_t_xs___pl",
    path: "/pl/landing_combo_mul_t_xs",
    meta: landing_combo_mul_t_xsROgelS5dGQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_t_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___en",
    path: "/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___vi",
    path: "/vi/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___ko",
    path: "/ko/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___ja",
    path: "/ja/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___de",
    path: "/de/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___fr",
    path: "/fr/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___es",
    path: "/es/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___it",
    path: "/it/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___pt",
    path: "/pt/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___es_Es",
    path: "/es_Es/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___zh-Hant",
    path: "/zh-Hant/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul_xs___pl",
    path: "/pl/landing_combo_mul_xs",
    meta: landing_combo_mul_xsVlskHzz7EGMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul_xs.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___en",
    path: "/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___vi",
    path: "/vi/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___ko",
    path: "/ko/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___ja",
    path: "/ja/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___de",
    path: "/de/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___fr",
    path: "/fr/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___es",
    path: "/es/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___it",
    path: "/it/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___pt",
    path: "/pt/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___es_Es",
    path: "/es_Es/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___zh-Hant",
    path: "/zh-Hant/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_mul___pl",
    path: "/pl/landing_combo_mul",
    meta: landing_combo_mulgwZzxFhn4mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_mul.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___en",
    path: "/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___vi",
    path: "/vi/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___ko",
    path: "/ko/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___ja",
    path: "/ja/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___de",
    path: "/de/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___fr",
    path: "/fr/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___es",
    path: "/es/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___it",
    path: "/it/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___pt",
    path: "/pt/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___es_Es",
    path: "/es_Es/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___zh-Hant",
    path: "/zh-Hant/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo_trial___pl",
    path: "/pl/landing_combo_trial",
    component: () => import("/usr/src/nuxt-app/pages/landing_combo_trial.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___en",
    path: "/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___vi",
    path: "/vi/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___ko",
    path: "/ko/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___ja",
    path: "/ja/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___de",
    path: "/de/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___fr",
    path: "/fr/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___es",
    path: "/es/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___it",
    path: "/it/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___pt",
    path: "/pt/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___es_Es",
    path: "/es_Es/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___zh-Hant",
    path: "/zh-Hant/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_combo___pl",
    path: "/pl/landing_combo",
    meta: landing_comboEqNna079RCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___en",
    path: "/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___vi",
    path: "/vi/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___ko",
    path: "/ko/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___ja",
    path: "/ja/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___de",
    path: "/de/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___fr",
    path: "/fr/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___es",
    path: "/es/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___it",
    path: "/it/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___pt",
    path: "/pt/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___es_Es",
    path: "/es_Es/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___zh-Hant",
    path: "/zh-Hant/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_cus_combo___pl",
    path: "/pl/landing_cus_combo",
    meta: landing_cus_combo9rAaBU03XpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_cus_combo.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___en",
    path: "/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___vi",
    path: "/vi/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___ko",
    path: "/ko/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___ja",
    path: "/ja/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___de",
    path: "/de/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___fr",
    path: "/fr/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___es",
    path: "/es/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___it",
    path: "/it/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___pt",
    path: "/pt/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___es_Es",
    path: "/es_Es/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___zh-Hant",
    path: "/zh-Hant/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_d___pl",
    path: "/pl/landing_d",
    component: () => import("/usr/src/nuxt-app/pages/landing_d.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___en",
    path: "/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___vi",
    path: "/vi/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___ko",
    path: "/ko/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___ja",
    path: "/ja/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___de",
    path: "/de/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___fr",
    path: "/fr/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___es",
    path: "/es/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___it",
    path: "/it/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___pt",
    path: "/pt/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___es_Es",
    path: "/es_Es/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___zh-Hant",
    path: "/zh-Hant/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_generic___pl",
    path: "/pl/landing_generic",
    meta: landing_generic9iPPHkb3nNMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_generic.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___en",
    path: "/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___vi",
    path: "/vi/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___ko",
    path: "/ko/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___ja",
    path: "/ja/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___de",
    path: "/de/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___fr",
    path: "/fr/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___es",
    path: "/es/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___it",
    path: "/it/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___pt",
    path: "/pt/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___es_Es",
    path: "/es_Es/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___zh-Hant",
    path: "/zh-Hant/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_jp___pl",
    path: "/pl/landing_jp",
    meta: landing_jpLD9c09rKL1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_jp.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___en",
    path: "/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___vi",
    path: "/vi/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___ko",
    path: "/ko/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___ja",
    path: "/ja/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___de",
    path: "/de/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___fr",
    path: "/fr/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___es",
    path: "/es/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___it",
    path: "/it/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___pt",
    path: "/pt/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___es_Es",
    path: "/es_Es/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___zh-Hant",
    path: "/zh-Hant/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s_ln___pl",
    path: "/pl/landing_k_s_ln",
    meta: landing_k_s_lnqpB5UsuSudMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s_ln.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___en",
    path: "/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___vi",
    path: "/vi/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___ko",
    path: "/ko/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___ja",
    path: "/ja/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___de",
    path: "/de/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___fr",
    path: "/fr/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___es",
    path: "/es/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___it",
    path: "/it/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___pt",
    path: "/pt/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___es_Es",
    path: "/es_Es/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___zh-Hant",
    path: "/zh-Hant/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k_s___pl",
    path: "/pl/landing_k_s",
    meta: landing_k_s1FLCG6i0AeMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___en",
    path: "/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___vi",
    path: "/vi/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___ko",
    path: "/ko/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___ja",
    path: "/ja/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___de",
    path: "/de/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___fr",
    path: "/fr/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___es",
    path: "/es/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___it",
    path: "/it/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___pt",
    path: "/pt/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___es_Es",
    path: "/es_Es/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___zh-Hant",
    path: "/zh-Hant/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_k___pl",
    path: "/pl/landing_k",
    meta: landing_kedGsVK9vKtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_k.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___en",
    path: "/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___vi",
    path: "/vi/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___ko",
    path: "/ko/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___ja",
    path: "/ja/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___de",
    path: "/de/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___fr",
    path: "/fr/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___es",
    path: "/es/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___it",
    path: "/it/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___pt",
    path: "/pt/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___es_Es",
    path: "/es_Es/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___zh-Hant",
    path: "/zh-Hant/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_ms___pl",
    path: "/pl/landing_ms",
    meta: landing_msOAeoZMK2HUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_ms.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___en",
    path: "/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___vi",
    path: "/vi/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___ko",
    path: "/ko/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___ja",
    path: "/ja/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___de",
    path: "/de/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___fr",
    path: "/fr/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___es",
    path: "/es/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___it",
    path: "/it/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___pt",
    path: "/pt/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___es_Es",
    path: "/es_Es/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___zh-Hant",
    path: "/zh-Hant/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_promotion___pl",
    path: "/pl/landing_promotion",
    meta: landing_promotionQyeGm5EpWVMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_promotion.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___en",
    path: "/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___vi",
    path: "/vi/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___ko",
    path: "/ko/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___ja",
    path: "/ja/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___de",
    path: "/de/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___fr",
    path: "/fr/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___es",
    path: "/es/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___it",
    path: "/it/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___pt",
    path: "/pt/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___es_Es",
    path: "/es_Es/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___zh-Hant",
    path: "/zh-Hant/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s_us___pl",
    path: "/pl/landing_s_us",
    meta: landing_s_uskLroAx9CWSMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___en",
    path: "/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___vi",
    path: "/vi/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___ko",
    path: "/ko/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___ja",
    path: "/ja/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___de",
    path: "/de/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___fr",
    path: "/fr/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___es",
    path: "/es/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___it",
    path: "/it/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___pt",
    path: "/pt/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___es_Es",
    path: "/es_Es/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___zh-Hant",
    path: "/zh-Hant/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_s___pl",
    path: "/pl/landing_s",
    meta: landing_sg6RaPSoiCOMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_s.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___en",
    path: "/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___vi",
    path: "/vi/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___ko",
    path: "/ko/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___ja",
    path: "/ja/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___de",
    path: "/de/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___fr",
    path: "/fr/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___es",
    path: "/es/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___it",
    path: "/it/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___pt",
    path: "/pt/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___es_Es",
    path: "/es_Es/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___zh-Hant",
    path: "/zh-Hant/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing_us___pl",
    path: "/pl/landing_us",
    meta: landing_usepqMEk2rKhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing_us.vue").then(m => m.default || m)
  },
  {
    name: "landing___en",
    path: "/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___vi",
    path: "/vi/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___ko",
    path: "/ko/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___ja",
    path: "/ja/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___de",
    path: "/de/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___fr",
    path: "/fr/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___es",
    path: "/es/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___it",
    path: "/it/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___pt",
    path: "/pt/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___es_Es",
    path: "/es_Es/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___zh-Hant",
    path: "/zh-Hant/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "landing___pl",
    path: "/pl/landing",
    meta: landingtYvM9RYcEsMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___en",
    path: "/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___vi",
    path: "/vi/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___ko",
    path: "/ko/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___ja",
    path: "/ja/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___de",
    path: "/de/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___fr",
    path: "/fr/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___es",
    path: "/es/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___it",
    path: "/it/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___pt",
    path: "/pt/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___es_Es",
    path: "/es_Es/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___zh-Hant",
    path: "/zh-Hant/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo_proceed___pl",
    path: "/pl/payment/combo_proceed",
    meta: combo_proceedcHvE4mzLgfMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo_proceed.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___en",
    path: "/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___vi",
    path: "/vi/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___ko",
    path: "/ko/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___ja",
    path: "/ja/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___de",
    path: "/de/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___fr",
    path: "/fr/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___es",
    path: "/es/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___it",
    path: "/it/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___pt",
    path: "/pt/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___es_Es",
    path: "/es_Es/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___zh-Hant",
    path: "/zh-Hant/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo-sub___pl",
    path: "/pl/payment/combo-sub",
    meta: combo_45subctpuBucKX3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo-sub.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___en",
    path: "/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___vi",
    path: "/vi/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___ko",
    path: "/ko/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___ja",
    path: "/ja/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___de",
    path: "/de/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___fr",
    path: "/fr/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___es",
    path: "/es/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___it",
    path: "/it/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___pt",
    path: "/pt/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___es_Es",
    path: "/es_Es/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___zh-Hant",
    path: "/zh-Hant/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment-combo___pl",
    path: "/pl/payment/combo",
    meta: comboytBzG1jcWhMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/combo.vue").then(m => m.default || m)
  },
  {
    name: "payment___en",
    path: "/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___vi",
    path: "/vi/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___ko",
    path: "/ko/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___ja",
    path: "/ja/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___de",
    path: "/de/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___fr",
    path: "/fr/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___es",
    path: "/es/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___it",
    path: "/it/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___pt",
    path: "/pt/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___es_Es",
    path: "/es_Es/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___zh-Hant",
    path: "/zh-Hant/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment___pl",
    path: "/pl/payment",
    meta: indexahiMYhfP5QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___en",
    path: "/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___vi",
    path: "/vi/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___ko",
    path: "/ko/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___ja",
    path: "/ja/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___de",
    path: "/de/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___fr",
    path: "/fr/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___es",
    path: "/es/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___it",
    path: "/it/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___pt",
    path: "/pt/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___es_Es",
    path: "/es_Es/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___zh-Hant",
    path: "/zh-Hant/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___pl",
    path: "/pl/payment/result",
    meta: resultHW2I97Yj3mMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/payment/result.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___en",
    path: "/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___vi",
    path: "/vi/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___ko",
    path: "/ko/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___ja",
    path: "/ja/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___de",
    path: "/de/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___fr",
    path: "/fr/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___es",
    path: "/es/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___it",
    path: "/it/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___pt",
    path: "/pt/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___es_Es",
    path: "/es_Es/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___zh-Hant",
    path: "/zh-Hant/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-license___pl",
    path: "/pl/policy/license",
    meta: licenseaM2iQ7Nv9LMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/license.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___en",
    path: "/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___vi",
    path: "/vi/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___ko",
    path: "/ko/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___ja",
    path: "/ja/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___de",
    path: "/de/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___fr",
    path: "/fr/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___es",
    path: "/es/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___it",
    path: "/it/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___pt",
    path: "/pt/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___es_Es",
    path: "/es_Es/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___zh-Hant",
    path: "/zh-Hant/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-privacy___pl",
    path: "/pl/policy/privacy",
    meta: privacyViD61SoAR1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/privacy.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___en",
    path: "/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___vi",
    path: "/vi/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___ko",
    path: "/ko/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___ja",
    path: "/ja/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___de",
    path: "/de/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___fr",
    path: "/fr/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___es",
    path: "/es/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___it",
    path: "/it/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___pt",
    path: "/pt/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___es_Es",
    path: "/es_Es/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___zh-Hant",
    path: "/zh-Hant/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "policy-terms___pl",
    path: "/pl/policy/terms",
    meta: termsPtyRC7fsTmMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/terms.vue").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___vi",
    path: "/vi/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___ko",
    path: "/ko/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___ja",
    path: "/ja/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___de",
    path: "/de/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___fr",
    path: "/fr/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___es",
    path: "/es/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___it",
    path: "/it/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___pt",
    path: "/pt/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___es_Es",
    path: "/es_Es/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___zh-Hant",
    path: "/zh-Hant/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___pl",
    path: "/pl/profile",
    meta: profile9minSuqEAQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "rdm___en",
    path: "/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___vi",
    path: "/vi/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___ko",
    path: "/ko/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___ja",
    path: "/ja/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___de",
    path: "/de/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___fr",
    path: "/fr/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___es",
    path: "/es/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___it",
    path: "/it/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___pt",
    path: "/pt/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___es_Es",
    path: "/es_Es/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___zh-Hant",
    path: "/zh-Hant/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm___pl",
    path: "/pl/rdm",
    meta: index2MwOG14fu8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___en",
    path: "/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___vi",
    path: "/vi/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___ko",
    path: "/ko/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___ja",
    path: "/ja/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___de",
    path: "/de/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___fr",
    path: "/fr/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___es",
    path: "/es/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___it",
    path: "/it/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___pt",
    path: "/pt/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___es_Es",
    path: "/es_Es/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___zh-Hant",
    path: "/zh-Hant/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "rdm-result___pl",
    path: "/pl/rdm/result",
    meta: indexA4hD9UO88wMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/rdm/result/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___en",
    path: "/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___vi",
    path: "/vi/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___ko",
    path: "/ko/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___ja",
    path: "/ja/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___de",
    path: "/de/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___fr",
    path: "/fr/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___es",
    path: "/es/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___it",
    path: "/it/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___pt",
    path: "/pt/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___es_Es",
    path: "/es_Es/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___zh-Hant",
    path: "/zh-Hant/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect___pl",
    path: "/pl/redirect",
    meta: indexM2Ny0tFVqQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___en",
    path: "/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___vi",
    path: "/vi/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___ko",
    path: "/ko/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___ja",
    path: "/ja/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___de",
    path: "/de/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___fr",
    path: "/fr/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___es",
    path: "/es/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___it",
    path: "/it/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___pt",
    path: "/pt/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___es_Es",
    path: "/es_Es/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___zh-Hant",
    path: "/zh-Hant/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "redirect-sensitiveLogin___pl",
    path: "/pl/redirect/sensitiveLogin",
    meta: sensitiveLoginZXXKQEQf1tMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/redirect/sensitiveLogin.vue").then(m => m.default || m)
  },
  {
    name: "referral___en",
    path: "/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___vi",
    path: "/vi/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___ko",
    path: "/ko/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___ja",
    path: "/ja/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___de",
    path: "/de/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___fr",
    path: "/fr/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___es",
    path: "/es/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___it",
    path: "/it/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___pt",
    path: "/pt/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___es_Es",
    path: "/es_Es/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___zh-Hant",
    path: "/zh-Hant/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "referral___pl",
    path: "/pl/referral",
    meta: indexhQJgsRAftHMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___en",
    path: "/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___vi",
    path: "/vi/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___ko",
    path: "/ko/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___ja",
    path: "/ja/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___de",
    path: "/de/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___fr",
    path: "/fr/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___es",
    path: "/es/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___it",
    path: "/it/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___pt",
    path: "/pt/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___es_Es",
    path: "/es_Es/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___zh-Hant",
    path: "/zh-Hant/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___pl",
    path: "/pl/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "s-id___en",
    path: "/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___vi",
    path: "/vi/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___ko",
    path: "/ko/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___ja",
    path: "/ja/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___de",
    path: "/de/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___fr",
    path: "/fr/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___es",
    path: "/es/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___it",
    path: "/it/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___pt",
    path: "/pt/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___es_Es",
    path: "/es_Es/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___zh-Hant",
    path: "/zh-Hant/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id___pl",
    path: "/pl/s/:id()",
    meta: _91id_93NVf0A3DMvLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___vi",
    path: "/vi/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___ko",
    path: "/ko/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___ja",
    path: "/ja/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___it",
    path: "/it/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___pt",
    path: "/pt/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___es_Es",
    path: "/es_Es/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___zh-Hant",
    path: "/zh-Hant/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___pl",
    path: "/pl/search",
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "start___en",
    path: "/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___vi",
    path: "/vi/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___ko",
    path: "/ko/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___ja",
    path: "/ja/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___de",
    path: "/de/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___fr",
    path: "/fr/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___es",
    path: "/es/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___it",
    path: "/it/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___pt",
    path: "/pt/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___es_Es",
    path: "/es_Es/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___zh-Hant",
    path: "/zh-Hant/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "start___pl",
    path: "/pl/start",
    component: () => import("/usr/src/nuxt-app/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___en",
    path: "/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___vi",
    path: "/vi/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___ko",
    path: "/ko/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___ja",
    path: "/ja/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___de",
    path: "/de/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___fr",
    path: "/fr/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___es",
    path: "/es/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___it",
    path: "/it/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___pt",
    path: "/pt/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___es_Es",
    path: "/es_Es/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___zh-Hant",
    path: "/zh-Hant/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitation___pl",
    path: "/pl/welfare/invitation",
    meta: invitationWJ4MZy7CAUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitation.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___en",
    path: "/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___vi",
    path: "/vi/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___ko",
    path: "/ko/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___ja",
    path: "/ja/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___de",
    path: "/de/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___fr",
    path: "/fr/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___es",
    path: "/es/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___it",
    path: "/it/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___pt",
    path: "/pt/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___es_Es",
    path: "/es_Es/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___zh-Hant",
    path: "/zh-Hant/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: "welfare-invitee___pl",
    path: "/pl/welfare/invitee",
    meta: index649PJ6jxVRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/welfare/invitee/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub080fa1cgF9Meta?.name,
    path: "/sitemap.xml",
    component: component_45stub080fa1cgF9
  }
]