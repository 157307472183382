import { ILocaleValue } from "@/constant/i18n-country"
import { CookiesKey } from "@/constant/cookies"
import { useGetPathLanguage } from "@/constant/useLocalPathConfig"

export const useI18nCountry = () =>
  useState("i18nCOuntry", () => {
    const { $i18n } = useNuxtApp()
    return $i18n?.locale?.value
  })

export const useI18nCountry1 = () =>
  useState<ILocaleValue>("i18nCOuntry1", () => {
    return useCookie(CookiesKey.LANGUAGE_1).value as ILocaleValue
  })

export async function setDynamicLanguage(nuxtApp: any, currentLanguage) {
  const { $i18n } = nuxtApp
  const { setLocale } = $i18n
  await setLocale(currentLanguage)
}
export async function setI18nLocale(nuxtApp: any, value) {
  const path = nuxtApp._route.path
  const language1 = useCookie(CookiesKey.LANGUAGE_1)
  if (useIsLandingByLanguage(path)) {
    language1.value = value
  }
  await setDynamicLanguage(nuxtApp, value)
}

export function setImGroupId(id) {
  useImGroupId().value = id
  useCookie(CookiesKey.IM_ID).value = id
}

// 是否开启注册的邀请码
export function setOpenChannelCode(open: boolean) {
  useOpenChannelCode().value = open
}

// 设置国家iso码
export function setCountryIsoCode(code: string) {
  useGetCountryIsoCode().value = code
}

export async function useSetI18nCountry(nuxtApp: any) {
  try {
    const { $arguments } = nuxtApp
    const { app_language } = $arguments || {}

    const select_language = useI18nCountry1().value

    const path = nuxtApp._route.path

    const query = nuxtApp._route.query

    let _language

    // 如果是三方中转页记录语言
    if (useIsSensitiveLogin(path)) {
      if (query.state) {
        _language = atob(decodeURIComponent(query.state)).split(":")[5] || ILocaleValue.en
      }
    }

    // 如果是落地页自己设置的语言走设置的语言
    if (useIsLandingByLanguage(path)) {
      // 如果是落地页自己带的非英语 语言逻辑判断 走链接的语言
      const path_language = useGetPathLanguage(path)
      _language = path_language || select_language
    }

    // 判断是否是用户自己选的
    const { group_id, is_open_im, is_open_channel_code, code, language, is_v2_subscription, ip_language } =
      await getLanguageAndIm()
    const res_language = _language || language

    const provideResult = {
      language: app_language || res_language || ILocaleValue.en,
      group_id,
      is_open_im,
      is_open_channel_code,
      iso_code: code,
      is_v2_subscription,
      ip_language
    }
    nuxtApp.provide("_need_init_data", provideResult)
  } catch (e) {
    console.log(`[i18n plugin api error]`, e)
  }
}
// 加载语言包
export const useLocaleMessages = async (locale) => {
  const $i18n = useNuxtApp().$i18n
  const { loadLocaleMessages } = $i18n
  await loadLocaleMessages(locale)
}
// 通用替换逻辑
export const useTranslateI18n = (name: string, args: any[] = [], nuxtApp?: any, locale?: string) => {
  //TODO 使用useI18n会有问题
  let $i18n
  if (nuxtApp) {
    $i18n = nuxtApp?.$i18n
  } else {
    $i18n = useNuxtApp().$i18n
  }
  const { t } = $i18n
  // if (process.client && !isUseI18n) {
  //   ;(locale as any).value = useCookie(CookiesKey.LANGUAGE).value || ILocaleValue.en
  //   isUseI18n = true
  // }
  let resultText = t(name)
  // Using translations of non-loaded locale
  if (locale) {
    // 外部调用提前加载语言包
    // loadLocaleMessages(locale)
    resultText = t(name, 1, { locale })
  } else {
    resultText = t(name)
  }
  if (args && args.length > 0) {
    args.forEach((item: any, index) => {
      const evotoKeyReg = new RegExp(`evoto%\\w+${index + 1}`, "i") //evoto%?+index
      // 静态文字
      const evotoStaticKeyReg = new RegExp(`static%${index + 1}(.+?)static%${index + 1}`, "i")
      // 静态图片
      const evotoStaticKeyImgReg = new RegExp(`staticImg%${index + 1}`, "i")
      if (typeof item === "undefined") {
        resultText = resultText.replace(evotoKeyReg, "")
      } else if (typeof item === "string") {
        resultText = resultText.replace(evotoKeyReg, item)
      } else if (typeof item === "number") {
        resultText = resultText.replace(evotoKeyReg, String(item))
      } else if (typeof item === "object" && !item.type) {
        // 防止首次加载出现undefined
        item.text = item.text || ""
        resultText = resultText.replace(evotoKeyReg, (...args) => {
          return `<span ${item.id ? `id=${item?.id}` : ""} ${item.class ? `class=${item?.class}` : ""}>${
            item.text
          }</span>`
        })
      } else if (typeof item === "object" && item.type === "static") {
        const { tag = "" } = item
        if (tag) {
          // 指定标签渲染 否则使用span
          const rex = new RegExp(`static%${index + 1}`, "gi")
          resultText = resultText.replace(evotoStaticKeyReg, function (...args) {
            let res = args[0]
            res = res.replace(rex, "").trim()
            res = `<${tag} ${item.id ? `id=${item?.id}` : ""} ${item.class ? `class=${item?.class}` : ""} ${
              item.onclick ? `onclick=${item?.onclick}` : ""
            } ${item.href ? `href=${item?.href}` : ""}
            ${item.target ? `target=${item?.target}` : ""}
            ${item.rel ? `rel=${item?.rel}` : ""}
            >${res}</${tag}>`
            return res
          })
        } else {
          const rex = new RegExp(`static%${index + 1}`, "gi")
          resultText = resultText.replace(evotoStaticKeyReg, function (...args) {
            let res = args[0]
            res = res.replace(rex, "").trim()
            res = `<span ${item.id ? `id=${item?.id}` : ""} ${item.class ? `class=${item?.class}` : ""} ${
              item.onclick ? `onclick=${item?.onclick}` : ""
            }>${res}</span>`
            return res
          })
        }
      } else if (typeof item === "object" && item.type === "img") {
        if (item.src) {
          resultText = resultText.replace(evotoStaticKeyImgReg, (...args) => {
            return `<img ${item.src ? `src=${item.src}` : ``} ${item.alt ? `alt='${item.alt}'` : ``} ${
              item.id ? `id=${item?.id}` : ""
            } ${item.class ? `class=${item?.class}` : ""}></img>`
          })
        }
      } else if (typeof item === "object" && item.type === "custom") {
        if (!Array.isArray(resultText)) {
          // 使用正则表达式将字符串分割为数组，并保留匹配的分隔符
          resultText = resultText.split(/(evoto%\w+\d)/).filter(Boolean)
          resultText = resultText.map((text, i) => {
            if (/(evoto%\w+\d)/.test(text)) {
              return {
                com: args[text.slice(text.length - 1) - 1]?.com
              }
            } else {
              return {
                t: text
              }
            }
          })
        }
      }
    })
  }
  if (Array.isArray(resultText)) {
    return () => {
      return (
        <div>
          {resultText.map((nodeFlag) => {
            if (nodeFlag.t) {
              return <span>{nodeFlag.t}</span>
            } else if (nodeFlag.com) {
              const com = nodeFlag.com
              return <com />
            }
          })}
        </div>
      )
    }
  } else {
    // 解决语境的问题（英文翻译相同 其他语言不同的情况下）
    return resultText.replace(/\$(\d+)\$/, "") //$+line+$
  }
}
