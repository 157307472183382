// import * as dayjs from "dayjs"
// import utc from "dayjs/plugin/utc"

// dayjs.extend(utc)
import moment from "moment"

export const getLocalDateString = (time: any, f = "YYYY-MM-DD HH:mm:ss") => {
  return moment.utc(time).local().format(f) //2019-03-06T17:11:55+08:00
}

export const getTimeUnix = (time: any) => {
  return moment.utc(time).unix() * 1000 // 转到秒级别
}

export function getCurrent30Day(f = "YYYY-MM-DD HH:mm:ss") {
  const end = new Date()
  const start = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
  return [moment(start).format(f), moment(end).format(f)]
}

export function getCurrent30Day2(f = "YYYY-MM-DD HH:mm:ss") {
  const end = new Date().setHours(0, 0, 0, 0)
  const start = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
  return [moment(start).format(f), moment(end).format(f)]
}

export const getLocalDateFormate = (date) => {
  const localDate = getLocalDateString(date, "YYYY-MM-DD")
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  const year = localDate.split("-")[0]
  const month = months[parseInt(localDate.split("-")[1]) - 1]
  const day = localDate.split("-")[2]

  return `${month} ${day} ${year}`
}

// 秒转换成年和月
export function convertSeconds(seconds) {
  const SECONDS_IN_DAY = 86400
  const SECONDS_IN_MONTH = SECONDS_IN_DAY * 30
  const SECONDS_IN_YEAR = SECONDS_IN_MONTH * 12

  let years = Math.floor(seconds / SECONDS_IN_YEAR)
  seconds %= SECONDS_IN_YEAR

  let months = Math.ceil(seconds / SECONDS_IN_MONTH)
  // If the remaining months equal 12, it should be counted as a year
  if (months >= 12) {
    years += Math.floor(months / 12)
    months = months % 12
  }

  return { years: years, months: months }
}

// 秒转换成月
export function convertSecondsToMonths(seconds) {
  const SECONDS_IN_DAY = 86400
  const SECONDS_IN_MONTH = SECONDS_IN_DAY * 30
  const months = Math.round(seconds / SECONDS_IN_MONTH)
  return { months: months }
}

// 秒 从今天开始算的日期
export function secondsToDateRange(seconds: number) {
  // 获取当前的日期
  const today = new Date()

  // 计算结束日期，给当前日期加上秒数
  const endDate = new Date(today.getTime() + seconds * 1000)

  // 格式化日期为 YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // 月份从 0 开始，所以要加 1
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  // 获取格式化的开始日期和结束日期
  const startDateFormatted = formatDate(today)
  const endDateFormatted = formatDate(endDate)

  // 返回 YYYY-MM-DD - YYYY-MM-DD 格式的日期范围
  return `${endDateFormatted}`
}

export function formatToDate(date: moment.MomentInput = undefined, format = "YYYY-MM-DD HH:mm:ss"): string {
  return moment(date).format(format)
}

export const dateUtil = moment
